 <template>
        <div class="main">
             <TheTopbar/>
             <button class="btn btn-secondary" @click="goBack()">back <b-badge class="text-dark" v-b-tooltip.hover title="Click to go back to the previous page">i</b-badge></button>
                <div class="main-container">
                      <div class="modal-header text-right">
                        <h5 class="m-2" style="color: gray">View Upload {{this.$route.params.poidKey}}</h5>
                        
                    </div>
                    <div class="modalBody" v-if="state == 'no'">
                            <div v-if="influencerPost.length == 0" >No submitted posts yet
                                <!-- <button :disabled="!hasDraft" @click="openSubmit()">Submit Post</button> -->
                            </div>
                    </div>
              
                    <div class="modalBody">
                        <div class="img">
                            <!-- <img :src="`data:image/png;base64,${imgUpload}`" class="userPic" /> -->
                            <embed :src="imgUpload " width="100%" height="1000px" alt="PDF">
                        </div>
                    </div>
         
                </div>
          </div>
</template>
<script>
import numeral from 'numeral'
import moment from 'moment';
import TheTopbar from '../components/TheTopbar.vue'


export default{
    name: 'ViewUpload',
    components:{
        TheTopbar,
       
    },
    data: function() {
        return{
          imgUpload: ''
            
                
        }
    },
    computed:{
        init() {
          return this.$store.state.init;
        },
        errors() {
          return this.$store.state.errors;
        },
    },

    methods:{
      downLoad(){
        this.viewImage = !this.viewImage
      },
      goBack(){
        this.$router.go(-1);
      },
      viewUpload(imageFile){
          this.filename = imageFile

          const params = {
            entityId: this.$route.params.entityId
            }
           
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/customer/businessfile/image/' + imageFile,
              }
              this.axios(options)
              .then((response) => {
               console.log(response.data)
               this.imgUpload = "data:application/pdf;base64," + response.data
          
                
              }).catch((err) => {
                  
              })
        },
     
      checkSession: function() {
				const options = {
				method: 'POST',
				headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
				url: '/ax/sessionCheck',
				};

				this.axios(options)
				.then((response) => { 
                    // console.log(response.data)
                  
					}).catch((err) => {
						
					if(err.response.data.msgCode == '00001') {
						window.location.href = "/#/login";
					}  

					if(err.response.data.msgCode == '00020') {
						
						window.location.href = "/#/setup-profile";
                   
						
					}  

					})
			},
       


            
            
    },
    beforeMount(){
      this.checkSession();
      this.showUpload();
    },
    mounted(){
      this.showUpload();
     
    
    }
}
        

        
</script>
     
<style lang="scss" scoped>



</style>